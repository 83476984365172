<template>
    <div class="services">
        <!--
        <header class="page-header">
            <div class="container">
                <div class="page-header-flex-wrap no-margin-bottom">
                    <h1 class="page-title section-header">Services</h1>
                </div>
            </div>
        </header>
        -->
        <Relates/>
    </div>
</template>

<script>
import Relates from '@/sections/Relates';

export default {
  name:       "Services",
  components: {Relates}
}
</script>

<style lang=scss>
</style>
